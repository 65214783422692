import { IGatsbyImageData } from "gatsby-plugin-image"
import { useEffect, useState } from "react"
import { Maybe } from "../../gatsby-graphql"

export type StatusType = {
    loading: boolean
    error: string | false | null
    data: Array<EventsType> | null
}

export type EventsType = {
    title: Maybe<string>
    path: Maybe<string>
    startDate: Maybe<string>
    endDate: Maybe<string>
    heroImage: Maybe<IGatsbyImageData>
    heroAltText: Maybe<string>
}


export const useGetLatestEvents = () => {
    const [status, setStatus] = useState<StatusType>({ loading: true, error: null, data: null })
    
    useEffect(() => {
        const data = async () => {
            const response = await fetch('/events.json')
            
            if(response.ok) {
                setStatus({loading: false, error: false, data: await response.json()})
            }else {
                setStatus({loading: false, error: response.statusText, data: null})
            }
        }
        data()

    }, [])

    return status
}