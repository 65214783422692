import { Hero } from "components/Hero"
import { Layout } from "components/Layout"
import { Seo } from "components/Seo"
import { graphql, navigate, PageProps } from "gatsby"
import { GatsbyImage, getImage, IGatsbyImageData, withArtDirection } from "gatsby-plugin-image"
import React, { useEffect, useMemo, useState } from "react"
import { GetHeroEventsQuery } from "../../gatsby-graphql"
import {Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import { useGetLatestEvents } from "hooks/useGetLatestEvents"
import { ClientOnly } from "components/ClientOnly"

export type DataProps = GetHeroEventsQuery

export type TEventCalendarItem = {
    path: string
    start: string
    end: string
    title: string
}

const localizer = momentLocalizer(moment)

const EventsPage: React.FC<PageProps<DataProps>> = ({ data }) => {
    const heroImageData = data.file?.childImageSharp
    const { data: eventsData } = useGetLatestEvents()
    const [events, setEvents] = useState<Array<TEventCalendarItem>>([])

    useEffect(() => {
        if (eventsData) {
            const now = new Date()
            const filteredArr = eventsData.filter((event, i) => !!event.endDate && new Date(event.endDate) > now)
            const calendarArr: Array<TEventCalendarItem> = filteredArr.map(({ startDate, endDate, title, path }, i) => {
                return {
                    start: startDate || '',
                    end: endDate || '',
                    title: title || '',
                    path: path || ''
                }
            })
            setEvents(calendarArr)

        }

    }, [eventsData])
    
    const images = useMemo(() => {
        return withArtDirection(getImage(heroImageData!.smallImage) as IGatsbyImageData, [
            {
                media: "(min-width: 75em)",
                image: getImage(heroImageData!.largeImage) as IGatsbyImageData
            },
            {
                media: "(min-width: 30em)",
                image: getImage(heroImageData!.mediumImage!) as IGatsbyImageData
            },
        ])
    }, [])

    return (
        <Layout pageClass="EventsPage">
            <Seo title="Events" />

            <Hero
                title={'Events'}
            >
                <GatsbyImage
                    image={images}
                    alt="Home Hero"
                    style={{ width: "100%" }}
                    loading="eager" />
            </Hero>

            <div className="inner typography">
                <ClientOnly>
                    <Calendar
                        localizer={localizer}
                        events={events || []}
                        onSelectEvent={(object: TEventCalendarItem) => object.path ? navigate(`${object.path}`) : null}
                        views={{
                            month: true,
                            week: false,
                            day: false,
                            agenda: false
                    
                        }}
                        view={"month"}
                        startAccessor="start"
                        endAccessor="end"
                        defaultView={'month'}
                    />
                </ClientOnly>
            </div>
        </Layout>
    )
}


export default EventsPage

export const query = graphql`
    query getHeroEvents {
        file(relativePath: {eq: "upolu_to-sua-ocean-trench-background.jpg"}) {
            childImageSharp {
                largeImage: gatsbyImageData(
                    quality: 75
                    height: 400
                    width: 2000
                    transformOptions: {cropFocus: CENTER}
                    layout: FIXED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                )
                mediumImage: gatsbyImageData(
                    quality: 75
                    height: 300
                    width: 1200
                    transformOptions: {cropFocus: CENTER}
                    layout: FIXED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                )
                smallImage: gatsbyImageData(
                    quality: 75
                    height: 400
                    width: 480
                    transformOptions: {cropFocus: CENTER}
                    layout: FIXED
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                )
            }
        }
    }
`